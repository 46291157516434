.home {
    background-image: url("../images/catBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 85vh;
}



.home-elements img {
    border-radius: 5%;
    margin-top: 8%;
    border-color: rgb(218, 64, 64);
    border-style: solid;
}

.home-elements p {
    margin-top: 5px;
    font-family: 'Lucida Sans';
}

.home-button {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.home-button a {
    color: black;
}

a {
    width: 180px;
    background-color: rgb(235, 195, 127);
    padding: 1%;
    border-style: none;
    border-radius: 5%;
    font-family: 'Lucida Sans';
    font-size: medium;
    font-weight: bold;
    transition:all 0.25s;
    text-align: center;
    color: black;
    text-decoration-line: none !important;
}

a:hover {
    color:white;
    background-color: rgb(175, 121, 28);
}


@media (max-width: 1200px) {

    .home-elements img {
        width: 440px;
    }
}

@media (max-width: 500px) {
    
    .home-elements img {
        width: 300px;
    }
}