.result-title {
    margin-top: 2%;
    display: flex;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-decoration: underline;
    text-decoration-color: rgb(218, 64, 64);
    
}

.my-spacer {
    padding-top: 4%;
}

.cat-elements {
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}