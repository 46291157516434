body{
    padding: 0;
    margin: 0;
}

.nav-box {
    padding: 1%;
    background-color: rgb(218, 64, 64);
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-box h1 {
    align-self: center;
    font-family: 'Lobster', cursive;
    color: black;
}

.nav-box h1:hover {
    transition: all 0.5s;
    color: white;
}

.modified-link {
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    color: none;
}

.modified-link:hover {
    background-color: transparent;
}

.admin-button {
    background-color: rgba(218,215,208,255);
    color: rgb(218, 64, 64);
    margin-top: 10px;
    position: absolute;
    left: 2%;
}

@media (max-width: 1200px) {
    .admin-button {
        width: 140px;
        font-size: 15px
    }
}

@media (max-width: 500px) {
    .admin-button {
        width: 90px;
        height: 65px;
        font-size: 13px;
        padding: 5px;
    }
}

@media (max-width: 375px) {
    .nav-box h1 {
        font-size: 28px;
    }
}

@media (max-width: 330px) {
    .nav-box h1 {
        font-size: 24px;
    }
}